var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "main-container" }, [
      _c("div", { staticClass: "main-wrap" }, [
        _c("div", { staticClass: "right-aside-container" }, [
          _c(
            "div",
            { staticClass: "right-aside-wrap" },
            [
              _c("RecommendListComponent", {
                attrs: { "recommend-list": _vm.recommendList },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "left-aside-container" }, [
          _c(
            "div",
            { staticClass: "left-aside-wrap" },
            [
              _c(
                "div",
                { staticClass: "article-list" },
                [
                  _vm._l(_vm.articleList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        on: {
                          click: function ($event) {
                            return _vm.linkDetail(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "article-list-item" }, [
                          _c(
                            "div",
                            { staticClass: "left" },
                            [
                              _c("el-image", {
                                staticClass: "image",
                                attrs: { src: item.coverUrl, alt: item.title },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "right" }, [
                            _c("h1", { staticClass: "title" }, [
                              _c("a", {
                                attrs: { href: item.link },
                                domProps: { textContent: _vm._s(item.title) },
                              }),
                            ]),
                            _c("p", {
                              staticClass: "content",
                              domProps: { textContent: _vm._s(item.content) },
                            }),
                            _c(
                              "div",
                              [
                                _vm._l(item.tags, function (tag) {
                                  return _c("i", {
                                    key: tag,
                                    staticClass: "tag",
                                    domProps: { textContent: _vm._s(tag) },
                                  })
                                }),
                                _c("span", {
                                  staticClass: "date",
                                  domProps: {
                                    textContent: _vm._s(item.gmtPublish),
                                  },
                                }),
                              ],
                              2
                            ),
                            _c("div", { staticStyle: { clear: "both" } }),
                          ]),
                        ]),
                        _c("el-divider"),
                      ],
                      1
                    )
                  }),
                  _c(
                    "div",
                    { staticClass: "btn-container" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn-load-more",
                          attrs: { round: "", type: "success" },
                          on: { click: _vm.loadArticleList },
                        },
                        [_vm._v("查看更多")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("FriendLinks"),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }